import React from "react";
import {formatterUS} from "../../../utils";

const DiamondRewardsDetails = ({ token }) => {
    return (
        <div className="totalPoolReward__details--block">
      <span className="totalPoolReward__details--text">
        Treasure Key #{token?.tokenId}
      </span>
            <span className="totalPoolReward__details--value">
        {token.amount ? formatterUS(token.amount/BigInt(1e18)) : 0}
      </span>
        </div>
    );
};

export default DiamondRewardsDetails;
