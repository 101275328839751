import Routers from "./routes";
import UserLayout from "./layouts/UserLayout";
import 'react-toastify/dist/ReactToastify.min.css';

import "./App.css";
import "./assets/styles/index.scss";
import "./assets/styles/variables.css";
import useWindowDimensions from "hooks/useWidowDimensions";
import {AppContextProvider} from "./contexts/appContext";
import {TokenContextProvider} from "./contexts/tokenContext";
import {StakingContextProvider} from "./contexts/stakingContext";
import {RewardsContextProvider} from "./contexts/rewardsContext";
import {WhiteListContextProvider} from "./contexts/whitelistContext";
import {BlockListenerContextProvider} from "./contexts/blockListenerContext";
import {ContractsContextProvider} from "./hooks/blockchain/useHandleContracts";
import {ToastContainer} from "react-toastify";
import {DiamondContextProvider} from "./contexts/diamondContext";


function getMobileOperatingSystem() {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

function App() {
  const windowParams = useWindowDimensions();
  const isMobile = windowParams?.width <= 450;
  if (!window?.ethereum) {
    return (
      <>
        <div className="wallet-warning-banner">
          No wallet detected!<br/>
          <p>You can get on the whitelist by clicking below, but you need to</p>
          <p>{!isMobile && ("install an ethereum wallet browser extension to play this game.")}</p>
          <p>{isMobile && ("use an ethereum wallet app browser to play this game on your phone.")}</p>
        </div>
        <div className="App">
          <UserLayout>
            <Routers />
          </UserLayout>
        </div>
      </>
    );
  } else {
    return (
      <div className="App">
          <ContractsContextProvider>
            <AppContextProvider>
              <TokenContextProvider>
                <DiamondContextProvider>
                  <StakingContextProvider>
                    <RewardsContextProvider>
                      <WhiteListContextProvider>
                        <BlockListenerContextProvider>
                          <UserLayout>
                            <>
                              <Routers />
                              <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="dark"
                              />
                            </>
                          </UserLayout>
                        </BlockListenerContextProvider>
                      </WhiteListContextProvider>
                    </RewardsContextProvider>
                  </StakingContextProvider>
                </DiamondContextProvider>
              </TokenContextProvider>
            </AppContextProvider>
          </ContractsContextProvider>
      </div>
    );
  }
}

export default App;
