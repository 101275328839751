import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import {useTokenContext} from "./tokenContext";
import useHandleContracts from "../hooks/blockchain/useHandleContracts";
import useHandleToastAlert from "../hooks/alert/useHandleToastAlert";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";
import {useStakingContext} from "./stakingContext";
import {useAppContext} from "./appContext";
const { success, error } = useHandleToastAlert();

const DiamondContext = createContext({
    diamondTokens: [],
    lockTokens: () => {},
    claimTokens: () => {},
    refreshDiamondTokens: () => {},
    startTime: 0,
    endTime: 0,
    totalAllocation: 0,
    bonusAllocation: 0,
    anyLocked: false
})

export const useDiamondContext = () => useContext(DiamondContext);

export const DiamondContextProvider = ({ children }) => {
    const { tokenList } = useTokenContext()
    const { refreshStakingBalances } = useStakingContext()
    const { refreshBalances } = useAppContext()
    const { contractDiamondWithSigner } = useHandleContracts()
    const [diamondTokens, setDiamondTokens] = useState([])
    const [endTime, setEndTime] = useState(0);
    const [startTime, setStartTime] = useState(0);
    const [totalAllocation, setTotalAllocation] = useState(0);
    const [bonusAllocation, setBonusAllocation] = useState(0);
    const [anyLocked, setAnyLocked] = useState(false);

    const { address, isConnected } = useWeb3ModalAccount();

    useEffect(() => {
        if (!contractDiamondWithSigner) {
            return
        }

        contractDiamondWithSigner.endTime().then((time) => setEndTime(Number(time)))
        contractDiamondWithSigner.startTime().then((time) => setStartTime(Number(time)))
    }, [contractDiamondWithSigner]);

    const refreshDiamondTokens= useCallback(async () => {
        if (!contractDiamondWithSigner) {
            return
        }

        const tokens = tokenList //.filter(token => token.zone === 1);
        const canConfigure = await contractDiamondWithSigner.getCanConfigure(tokens.map(token => token.tokenId))

        const [pending, totals, claimed, locked] = await contractDiamondWithSigner.getKeyInfo(tokens.map(token => token.tokenId))

        // const pending = await contractDiamondWithSigner.pendingRewards(tokens.map(token => token.tokenId))
        // const totals = await contractDiamondWithSigner.getTotalRewards(tokens.map(token => token.tokenId))
        // const claimed = await contractDiamondWithSigner.getClaimed(tokens.map(token => token.tokenId))


        const diamonds = [];
        for (let i = 0; i < pending.length; i++) {
            if (pending[i] === BigInt(0)) {
                continue
            }

            diamonds.push({
                tokenId: tokens[i].tokenId,
                amount: pending[i],
                canConfigure: canConfigure[i],
                total: totals[i],
                claimed: claimed[i],
                isLocked: locked[i]
            })
        }

        const lockedKeys = diamonds.filter((diamond) => diamond.isLocked)

        const lockedTotal = 0.35 * Number(lockedKeys.reduce((toRet, token) => toRet + token.total, BigInt(0))/(BigInt(1e18)))
        const base = Number(diamonds.reduce((toRet, token) => toRet + token.total, BigInt(0))/(BigInt(1e18)))

        setTotalAllocation(base)
        setBonusAllocation(lockedTotal)
        setAnyLocked(lockedKeys.length > 0)
        setDiamondTokens(diamonds)
    }, [tokenList, contractDiamondWithSigner])

    useEffect(() => {
        refreshDiamondTokens()
    }, [refreshDiamondTokens]);

    const lockTokens = useCallback(async () => {
        try {
            await contractDiamondWithSigner.lockKeys(diamondTokens.map(token => token.tokenId))
        } catch (err) {
            console.error(err)
            error('Keys could not be locked. See docs.');
        }
    }, [diamondTokens, contractDiamondWithSigner])

    const claimTokens = useCallback(async () => {
        try {
            await (await contractDiamondWithSigner.claimReward(address, diamondTokens.map(token => token.tokenId))).wait(2)
            await refreshDiamondTokens()
            await refreshStakingBalances()
            await refreshBalances()
        } catch (err) {
            console.error(err)
            error('Claim failed. Try again.');
        }
    }, [diamondTokens, contractDiamondWithSigner, address, refreshDiamondTokens])

    const value = {
        diamondTokens,
        lockTokens,
        claimTokens,
        endTime,
        startTime,
        refreshDiamondTokens,
        totalAllocation,
        bonusAllocation,
        anyLocked
    };

    return (
        <DiamondContext.Provider value={value}>
            {children}
        </DiamondContext.Provider>
    );
}