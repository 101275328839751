import {useCallback, useContext} from "react";
import { Context } from "../../store";
import useHandleToastAlert from "../alert/useHandleToastAlert";
import useHandleContracts from "./useHandleContracts";
import {
  ALERT_STATUS_FAILURE,
  ALERT_STATUS_SUCCESS,
  DEACTIVATE_KEY_ALERT,
  UPGRADE_KEY_ALERT,
} from "../../constant/alert";
import {useTokenContext} from "../../contexts/tokenContext";
import {useAppContext} from "../../contexts/appContext";
import {useWhiteListContext} from "../../contexts/whitelistContext";
import {getImagePath} from "../../constant/blockchain";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";

/* global BigInt */

export default function useHandleNFT() {
  const [_, ACTION] = useContext(Context);
  const { balances, refreshBalances } = useAppContext();
  const { selectedToken, tokenList, setTokenList } = useTokenContext();
  const { whiteList, commissions } = useWhiteListContext();
  const handleToastAlert = useHandleToastAlert();
  const {
    contractNFKeyWithSigner,
    contractNFKeyStakingWithSigner,
    ethersProvider,
    contractSmarterCoinWithSigner,
    contractTresrCoinWithSigner,
    contractLpCoinTRESRAVAXWithSigner,
    contractLpStakingTRESRAVAXWithSigner,
    contractTresrStakingCoinWithSigner,
    contractLpCoinSMRTRAVAXWithSigner,
    contractERC20WithSigner,
    contractViews,
    contractNFKey,
  }= useHandleContracts();
  const { address } = useWeb3ModalAccount();

  const getDetailsForTokens = useCallback(async (tokenIds) => {
    if (!contractViews) {
      return [];
    }

    return contractViews
      .getInfoForKeys(tokenIds)
      .then((infos) => {
        const toRet = [];
        for (const info of infos) {
          const level = Number(info['level']);
          toRet.push({
            tokenId: Number(info['tokenId']),
            image: getImagePath(level),
            name: info['name'],
            description: info['description'],
            owner: info['owner'],
            symbol: info['symbol'],
            level,
            zone: Number(info['zone']),
            tierTresr: Number(info['tierTresr']),
            tierUpdatedTime: Number(info['tierUpdatedTime']),
            upgradeStart: Number(info['upgradesStart']),
            upgradeToDelay:Number(info['upgradeToDelay']) * 1000,
            staked: info['staked'],
            keyUpgradeAmount: Math.ceil(Number(info['keyUpgradeAmount']) / 10 ** 18),
            unlockChestAmount: Number(info['unlockChestAmount']) / 10 ** 18,
            openChestProbability: Number(info['openChestProbabilityNum']) / 10,
            keyUpgradeDelay: Number(info['keyUpgradeDelay']),
            keyBaseReward: Number(info['keyBaseReward']) / 10 ** 18,
            rewardsPerSecond: Number(info['rewardsPerSecond']) / 10 ** 18,
            timeToTreasureExpiration: Number(info['timeToTreasureExpiration']),
            openChestAvaxFee: Number(info['openChestAvaxFee']) / 10 ** 18,
            upgradeInProgress: false,
          });
        }
        return toRet;
      });
  }, [contractViews]);

  const getTokenList = useCallback(async (userAddress)  => {
    if (!contractNFKey) {
      return;
    }
    try {
      const tokenOwner = await contractNFKey
        .tokensOfOwner(userAddress)
        .then(tokens => tokens.map(token => Number(token)));

      const tokensToGet = tokenOwner.filter(tokenId => !tokenList.find(t => t.tokenId === tokenId));
      const tokenInfo = await getDetailsForTokens(tokensToGet);

      return [
        ...tokenList,
        ...tokenInfo
      ];
    } catch (error) {
      // Handle error or non-existent token
      console.info('got error from dev check of owned nfts', error)
    }
  }, [tokenList, contractNFKey, contractViews, getDetailsForTokens]);

  const updateProfileBalance = async (address) => {
    const res = await Promise.all([
      contractSmarterCoinWithSigner.balanceOf(address),
      contractTresrCoinWithSigner.balanceOf(address),
      ethersProvider.getBalance(address),
      contractLpCoinTRESRAVAXWithSigner.balanceOf(address),
      contractLpStakingTRESRAVAXWithSigner
        .stakedBalanceOf(address),
      contractTresrStakingCoinWithSigner
        .stakedBalanceOf(address),
      contractTresrStakingCoinWithSigner
        .pendingVeTresr(address),
      contractLpCoinSMRTRAVAXWithSigner
        .balanceOf(address),
    ]);

    return {
      smrtrBalance: Number(res[0]) /10 ** 18,
      tresrBalance: Number(res[1]) / 10**18,
      avaxBalance: Number(res[2]) / 10**18,
      lpTresrAvaxBalance: Number(res[3]) / 10**18,
      lpStakedTresrAvaxBalance: Number(res[4]) / 10 ** 18,
      veTresrBalance: Number(res[5]) /10 ** 18,
      unclaimedVeTresrBalance: Number(res[6])  / 10 ** 18,
      lpSmrtrAvaxBalance: Number(res[7]) /10 ** 18,
    }
  };

  const balanceOfERC20 = async (walletAddress, contractAddress, count = 3) => {
    return contractERC20WithSigner(contractAddress)
      .balanceOf(walletAddress)
      .then((tx) => Number(tx) / 10**18)
      .catch(() =>
        count ? balanceOfERC20(walletAddress, contractAddress, count - 1) : null
      );
  };

  const getUpgradeDelays = useCallback(async (tokenIds) => {
    return contractViews
      .getUpgradeDelays(tokenIds)
      .then((delays) => delays.map((delay) => Number(delay)));
  }, [contractViews]);

  const getStartUpgradeDelays = useCallback(async (tokenIds) => {
    return contractViews
      .getStartUpgradeDelays(tokenIds)
      .then((delays) => delays.map((delay) => Number(delay)));
  }, [contractViews]);

  const calcRewardByTokens = async (tokenList) => {
    if (tokenList?.length === 0) return [];
    const rewardList = await contractNFKeyStakingWithSigner
      .pendingBaseRewardByTokens(tokenList);
    return rewardList.map(
      (reward) => Number(reward) /10**18
    );
  };


  const getAmountUpgradeKeys = useCallback(async (tokenIds) => {
    return contractViews
      .getUpgradeKeyCostForTokens(tokenIds)
      .then((costs) => {
        const toRet = [];
        for (let i = 0; i < costs.length; i++) {
          toRet.push(Math.ceil(Number(costs[i]) / 10 ** 18));
        }
        return toRet;
      })
  }, [contractViews]);

  const approveSMRTRByAmount = useCallback(async (amount) => {
    const sumToApprove = BigInt(amount)*BigInt(10**18);

    const allowance = await contractSmarterCoinWithSigner
      .allowance(address, process.env.REACT_APP_NFKEY_ADDRESS);

    if (allowance >= sumToApprove) {
      return true;
    }

    return contractSmarterCoinWithSigner
      .approve(process.env.REACT_APP_NFKEY_ADDRESS, sumToApprove)
      .then(async (tx) => {
        await tx.wait();
        ACTION.SET_TRANSANCTION_HASH(tx?.hash);
        return true;
      })
      .catch((err) => {
        return err;
      });
  }, [address, contractSmarterCoinWithSigner]);

  const approveSMRTR = useCallback(async () => {
    if (!selectedToken?.level) {
      throw new Error(`The token has an invalid level`)
    }

    const amountUpgradeKey = await getAmountUpgradeKeys([selectedToken?.tokenId])
    if (!amountUpgradeKey) return;
    const toApprove = BigInt(Math.ceil(amountUpgradeKey * 1000)) *  BigInt(10**15)

    const allowance = await contractSmarterCoinWithSigner
      .allowance(address, process.env.REACT_APP_NFKEY_ADDRESS);
    if (allowance >= toApprove) {
      return true;
    }

    const gas = await contractSmarterCoinWithSigner
      .approve.estimateGas(address, process.env.REACT_APP_NFKEY_ADDRESS);

    return contractSmarterCoinWithSigner
      .approve(process.env.REACT_APP_NFKEY_ADDRESS, toApprove, {
        gasLimit: gas * BigInt(130) / BigInt(100)
      })
      .then(async (tx) => {
        await tx.wait();
        ACTION.SET_TRANSANCTION_HASH(tx?.hash);
        return true;
      })
      .catch((err) => {
        throw err;
      });
  }, [address, selectedToken, contractSmarterCoinWithSigner, getAmountUpgradeKeys]);



  const bulkUpgradeKeys = async (tokenList, amountSMRTR) => {
    const maxBatchSize = 150;
    const numBatches = Math.ceil(tokenList.length / maxBatchSize);
    for (let i = 0; i < numBatches; i++) {
      const start = i * maxBatchSize;
      const batchIds = tokenList.slice(start, start + maxBatchSize);
      const gas = await contractNFKeyWithSigner.bulkUpgradeKeys.estimateGas(batchIds);
      await contractNFKeyWithSigner
        .bulkUpgradeKeys(batchIds, {gasLimit: gas * BigInt(130) / BigInt(100)})
        .then(async (tx) => {
          await tx.wait();
        })
        .catch((err) => {
          console.log(err)
          ACTION.SET_ALERT(
            true,
            ALERT_STATUS_FAILURE,
            UPGRADE_KEY_ALERT(batchIds[0], false)
          );
          return null;
        });
    }
    return true;
  };

  const getZonesOpen = useCallback(async () => {
    if (!contractNFKey) {
      return [
        false, false, false, false, false
      ];
    }

    const results = await Promise.all([
      contractNFKey
        .isMintOpen(1),
      contractNFKey
        .isMintOpen(2),
      contractNFKey
        .isMintOpen(3),
      contractNFKey
        .isMintOpen(4),
      contractNFKey
        .isMintOpen(5),
    ]);

    return results;
  }, [contractNFKey]);

  const batchMint = async (zones, amounts) => {
    if (zones.length === 0 || amounts.length === 0) {
      throw new Error(`No zones selected to mint`)
    }
    let commission = 0;
    for (let i = 0; i < zones.length; i++) {
      if (Array.isArray(commissions[zones[i] - 1])) {
        if (amounts[i] > 1) {
          commission += commissions[zones[i] - 1][1]  * amounts[i];
        } else {
          commission += commissions[zones[i] - 1][0] * amounts[i];
        }
      } else {
        commission += commissions[zones[i] - 1] * amounts[i];
      }
    }

    if (commission > balances.balanceAvax) {
      handleToastAlert.error("Insufficient balance");
      return;
    }
    const fullCommission = BigInt(Math.ceil(commission * 1000)) *  BigInt(10**15);
    const gas = await contractNFKeyWithSigner.batchMint.estimateGas(address, zones, amounts, whiteList.merkle, whiteList.proof, {
      value: fullCommission,
    });
    return contractNFKeyWithSigner
      .batchMint(address, zones, amounts, whiteList.merkle, whiteList.proof, {
        value: fullCommission,
        gasLimit: gas * BigInt(130) / BigInt(100)
      })
      .then(async (tx) => {
        const answer = await tx.wait();
        ACTION.SET_TRANSANCTION_HASH(tx?.hash);
        const transactionLogList = answer?.logs?.filter(
          (log) => log?.address === process.env.REACT_APP_NFKEY_ADDRESS
        );
        return transactionLogList;
      }).catch((err) => {
        console.log(address, zones, amounts, whiteList.merkle, whiteList.proof, {
          value: fullCommission,
        });
        console.log(err);
        throw err;
      })
  };



  const unstake = async () => {
    await contractNFKeyStakingWithSigner
      .unstake(selectedToken?.tokenId)
      .then(async (tx) => {
        await tx.wait(2);
        ACTION.SET_TRANSANCTION_HASH(tx?.hash);
        ACTION.SET_ALERT(
          true,
          ALERT_STATUS_SUCCESS,
          DEACTIVATE_KEY_ALERT(selectedToken?.tokenId, true)
        );
        refreshBalances();
      })
      .catch((err) => {
        ACTION.SET_ALERT(
          true,
          ALERT_STATUS_FAILURE,
          DEACTIVATE_KEY_ALERT(selectedToken?.tokenId, false)
        );

        return null;
      });
  };


  const loadNFTBalance = useCallback(async (contractAddress) => {
    if (address) {
      const tokenList = await getTokenList(contractAddress);
      if (!tokenList?.length) return;
      setTokenList(tokenList);
    }
  }, [getTokenList, address, setTokenList]);

  return {
    updateProfileBalance,
    getAmountUpgradeKeys,
    loadNFTBalance,
    approveSMRTR,
    bulkUpgradeKeys,
    unstake,
    batchMint,
    balanceOfERC20,
    calcRewardByTokens,
    getUpgradeDelays,
    getStartUpgradeDelays,
    approveSMRTRByAmount,
    getTokenList,
    getZonesOpen,
    getDetailsForTokens,
  };
}
