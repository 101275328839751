import React, {useCallback, useContext, useEffect, useState} from "react";
import homeArrow from "../../../assets/images/home_arrow.svg";
import homeArrowMobile from "../../../assets/images/home_arrow_mobile.svg";
import homeArrowDark from "../../../assets/images/home_arrow_blue.svg";
import homeArrowDarkMobile from "../../../assets/images/home_arrow_mobile.svg";
import "./style.scss";
import { Context } from "../../../store";
import ZoneItem from "./ZoneItem";
import MarketplaceButton from "../../common/MarketplaceButton";
import useWindowDimensions from "../../../hooks/useWidowDimensions";
import useHandleNFT from "../../../hooks/blockchain/useHandleNFT";
import TransactionLoadingModal from "../../common/Modals/TransactionLoadingModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import SuccessMintModal from "../../common/Modals/SuccessMintModal";
import useGetTokenPrice from "hooks/blockchain/useGetTokenPrice";
import MintConfirmationModal from "components/common/Modals/MintConfirmationModal";
import {useAppContext} from "../../../contexts/appContext";
import { useWhiteListContext } from "../../../contexts/whitelistContext";
import useHandleToastAlert from "../../../hooks/alert/useHandleToastAlert";
import PubSub from "pubsub-js";
import {PubSubEvents} from "../../../constant/events";
import OpenTreasureBoxModal from "../../common/Modals/OpenTreasureBoxModal";
import AcceptTermsModal from "../../common/Modals/AcceptTermsModal";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";
import {sleep} from "../../../utils";
import {useTokenContext} from "../../../contexts/tokenContext";

const HomeZoneListNew = ({ animationCallback, isLoadedList }) => {
  const [{ theme }] = useContext(Context);
  const { balances, hasAcceptedTerms, acceptTerms } = useAppContext();
  const { address, isConnected } = useWeb3ModalAccount()
  const { whiteList, commissions } = useWhiteListContext();
  const handleToastAlert = useHandleToastAlert();
  const { refreshTokenList } = useTokenContext();
  const [zoneSumAmount, setZoneSumAmount] = useState(0);
  const [zoneSumCommission, setZoneSumCommission] = useState(0);
  const [zoneChecked, setZoneChecked] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [zoneAmount, setZoneAmount] = useState([0, 0, 0, 0, 0]);
  const [mintedTokensList, setMintedTokensList] = useState([]);
  const [zoneIsOpen, setZoneIsOpen] = useState([
    false,
    false,
    false,
    false,
    false,
  ])

  const windowDimensions = useWindowDimensions();
  const handleNFT = useHandleNFT();
  const handleTransactionLoadingModal = useHandleModal();
  const handleSuccessMintModal = useHandleModal();
  const handleMintConfirmationModal = useHandleModal();
  const handleAcceptTermsModal = useHandleModal();

  const isMobile = windowDimensions?.width <= 1000;
  const isDark = theme === "dark";
  const MAX_TOKEN_PER_MINT = 40;
  const { getTokenPrice } = useGetTokenPrice();
  const [AVAXPrice, setAVAXPrice] = useState(0);
  const getAVAXPrice = async () => {
    const auctionTokenRes = await getTokenPrice({
      name: "avax",
      pool: "0xd446eb1660f766d533beceef890df7a69d26f7d1",
    });
    setAVAXPrice(auctionTokenRes.price);
  };

  const onCloseTransactionLoadingModal = () => {
    handleTransactionLoadingModal.close();
  }

  const getUserZones = async () => {
    animationCallback();
  };

  useEffect(() => {
    if (!whiteList.details.zone1 || !zoneIsOpen[0]) {
      return;
    }

    setZoneChecked(checked =>
      checked?.map((item, key) =>
        key === 0 ? true : item
      )
    );

    setZoneAmount( amount =>
      amount?.map((item, key) =>
        key === 0 ? 1 : item
      )
    );

  }, [whiteList, zoneIsOpen]);

  useEffect(() => {
    const getZones = async () => {
      const zones = await handleNFT.getZonesOpen();
      setZoneIsOpen(zones);
    }
    getZones();
  }, [handleNFT.getZonesOpen]);

  const calcSumCommission = useCallback(() => {
    if (!commissions || commissions.length === 0) {
      return;
    }
    return zoneAmount
      ?.map((item, key) => {
        if (key === 0) return +(item * commissions[0]).toFixed(2);
        if (key === 1) return +(item * commissions[1]).toFixed(2);
        if (key === 2) return +(item * commissions[2]).toFixed(2);
        if (key === 3) {
          if (item > 1) {
           return +(item * commissions[3][1]).toFixed(2);
          } else {
            return +(item * commissions[3][0]).toFixed(2);
          }
        }
        if (key === 4) return +(item * commissions[4]).toFixed(2);
        else return 0;
      })
      ?.filter((_, key) => zoneChecked[key])
      ?.reduce((acc, val) => +(acc + val).toFixed(2), 0);
  }, [commissions, zoneAmount]);

  const calcSumAmount = () => {
    return zoneAmount
      ?.filter((_, key) => zoneChecked[key])
      ?.reduce((acc, val) => acc + val, 0);
  };

  const onAcceptTerms = useCallback(async () => {
    try {
      await acceptTerms();
      handleAcceptTermsModal.close();
    } catch {
      handleToastAlert.error("Error accepting terms. Please try again");
      handleAcceptTermsModal.close();
    }
  }, [acceptTerms])

  const onMint = async () => {
    if (!hasAcceptedTerms) {
      handleAcceptTermsModal.open();
    } else {
      handleMintConfirmationModal.close();
      // if (zoneSumCommission > balances.balanceAvax) {
      //   handleToastAlert.error("Insufficient balance");
      // } else {

        handleTransactionLoadingModal.open();

        const promiseList = [
          {zone: 1, amount: zoneAmount[0]},
          {zone: 2, amount: zoneAmount[1]},
          {zone: 3, amount: zoneAmount[2]},
          {zone: 4, amount: zoneAmount[3]},
          {zone: 5, amount: zoneAmount[4]},
        ].filter((item, key) => zoneChecked[key]);

        let zones = promiseList.map((item) => item.zone);
        let amounts = promiseList.map((item) => item.amount);
        handleNFT
          .batchMint(zones, amounts)
          .then((res) => {
            setMintedTokensList(
              res
                ?.flat(1)
                ?.map((item) => parseInt(item?.topics[3], 16))
                ?.filter((item) => !Number.isNaN(item))
            );
          })
          .catch(err => {
            console.log(err);
            if (zoneSumCommission > balances.balanceAvax) {
              handleToastAlert.error("Error minting. Insufficient balance - add more AVAX");
            } else {
              handleToastAlert.error("Error minting. Try again or contact support");
            }
            handleTransactionLoadingModal.close();
          })
        getUserZones();
      // }
    }
  };

  useEffect(() => {
    const batchMintToken = PubSub.subscribe(PubSubEvents.NFKEYS_BATCH_MINT, () => {
      handleNFT.loadNFTBalance(address).then(async () => {
        await sleep(10);
        await refreshTokenList();
        handleTransactionLoadingModal.close();
        handleSuccessMintModal.open();
        setZoneChecked([false, false, false, false, false]);
        setZoneAmount([0, 0, 0, 0, 0]);
      });
    });

    return () => {
      PubSub.unsubscribe(batchMintToken);
    };
  }, [address, refreshTokenList]);

  useEffect(() => {
    if (isConnected) getUserZones();
    getAVAXPrice();
  }, [isConnected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setZoneSumAmount(calcSumAmount());
    setZoneSumCommission(calcSumCommission());
  }, [zoneAmount, zoneChecked, calcSumCommission]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isLoadedList) return null;
  return (
    <div className="homeZoneListNew">
      {(whiteList.details.zone1 || whiteList.details.zone2 || whiteList.details.zone3 || whiteList.details.zone4) ? (
        <div className="homeZoneListNew__topText">
          {isMobile ? (
            <img
              alt={""}
              src={isDark ? homeArrowMobile : homeArrowDarkMobile}
            />
          ) : (
            <img alt={""} src={isDark ? homeArrow : homeArrowDark} />
          )}
          <p>Click Diamond Below to Mint.</p>
        </div>
      ) : (
        <div className="homeZoneListNew__topText -top-[90px]">
          {isMobile ? (
            <img
              alt={""}
              src={isDark ? homeArrowMobile : homeArrowDarkMobile}
            />
          ) : (
            <img alt={""} src={isDark ? homeArrow : homeArrowDark} />
          )}
          <p>Click Diamond Below to Mint.</p>
        </div>
      )}
      <div className="homeZoneListNew__list">
        {whiteList.details.zone1 && (
          <ZoneItem
            title="Diamond Hand"
            isDisabled={!zoneChecked[0]}
            amount={zoneAmount[0]}
            zoneOpen={zoneIsOpen[0]}
            onChecked={() =>
              setZoneChecked(
                zoneChecked?.map((item, key) =>
                  key === 0 ? !zoneChecked[0] : item
                )
              )
            }
            setAmount={(value) =>
              +value <= MAX_TOKEN_PER_MINT &&
              setZoneAmount(
                zoneAmount?.map((item, key) => (key === 0 ? value : item))
              )
            }
            price={zoneIsOpen[0] ? "Free Mint" : "Not Open"}
          />
        )}
        {whiteList.details.zone2 && (
          <ZoneItem
            title="Dragon Hand"
            withInput
            zoneOpen={zoneIsOpen[1]}
            max={1}
            isDisabled={!zoneChecked[1]}
            amount={zoneAmount[1]}
            onChecked={() =>
              setZoneChecked(
                zoneChecked?.map((item, key) =>
                  key === 1 ? !zoneChecked[1] : item
                )
              )
            }
            setAmount={(value) =>
              +value <= MAX_TOKEN_PER_MINT &&
              setZoneAmount(
                zoneAmount?.map((item, key) => (key === 1 ? value : item))
              )
            }
            price={zoneIsOpen[1] ? `${+(zoneAmount[1] * commissions[1]).toFixed(2)} AVAX` : 'Not Open'}
          />
        )}
        {whiteList.details.zone3 && (
          <ZoneItem
            title="Emerald"
            isDisabled={!zoneChecked[2]}
            zoneOpen={zoneIsOpen[2]}
            withInput
            amount={zoneAmount[2]}
            onChecked={() =>
              setZoneChecked(
                zoneChecked?.map((item, key) =>
                  key === 2 ? !zoneChecked[2] : item
                )
              )
            }
            setAmount={(value) =>
              +value <= MAX_TOKEN_PER_MINT &&
              setZoneAmount(
                zoneAmount?.map((item, key) => (key === 2 ? value : item))
              )
            }
            price={zoneIsOpen[2] ? `${+(zoneAmount[2] * commissions[2]).toFixed(2)} AVAX` : 'Not Open'}
          />
        )}
        {whiteList.details.zone4 && (
          <ZoneItem
            title="Sapphire"
            isDisabled={!zoneChecked[3]}
            zoneOpen={zoneIsOpen[3]}
            withInput
            amount={zoneAmount[3]}
            onChecked={() =>
              setZoneChecked(
                zoneChecked?.map((item, key) =>
                  key === 3 ? !zoneChecked[3] : item
                )
              )
            }
            setAmount={(value) =>
              +value <= MAX_TOKEN_PER_MINT &&
              setZoneAmount(
                zoneAmount?.map((item, key) => (key === 3 ? value : item))
              )
            }
            price={zoneIsOpen[3] ? `${+(zoneAmount[3] * (zoneAmount[3] > 1 ? commissions[3][1] :  commissions[3][0])).toFixed(2)} AVAX` : 'Not Open'}
          />
        )}
        <ZoneItem
          title="Ruby"
          isDisabled={!zoneChecked[4]}
          zoneOpen={zoneIsOpen[4]}
          withInput
          amount={zoneAmount[4]}
          onChecked={() =>
            setZoneChecked(
              zoneChecked?.map((item, key) =>
                key === 4 ? !zoneChecked[4] : item
              )
            )
          }
          setAmount={(value) =>
            +value <= MAX_TOKEN_PER_MINT &&
            setZoneAmount(
              zoneAmount?.map((item, key) => (key === 4 ? value : item))
            )
          }
          price={zoneIsOpen[4] ? `${+(zoneAmount[4] * commissions[4]).toFixed(2)} AVAX` : 'Not Open'}
        />
      </div>

      <div className="homeZoneListNew__mintAll">
        <MarketplaceButton
          onClick={onMint}
          title={!hasAcceptedTerms ? 'Sign' : zoneSumCommission > balances.balanceAvax ? `Insufficient AVAX - ` : `Mint ${zoneSumAmount} Keys for ${zoneSumCommission} AVAX`}
          disabled={hasAcceptedTerms && (
            zoneSumAmount > MAX_TOKEN_PER_MINT ||
            // zoneSumCommission > balances.balanceAvax ||
            zoneChecked?.every((item) => !item)
          )}
          isBlue
          withShadow
        />
        <div className="homeZoneListNew__mintAll--text">
          You can mint max {MAX_TOKEN_PER_MINT} Keys per transaction.
        </div>
      </div>
      {handleTransactionLoadingModal.isActive && (
        <TransactionLoadingModal
          isOpen={handleTransactionLoadingModal.isActive}
          onClose={onCloseTransactionLoadingModal}
        />
      )}
      {handleMintConfirmationModal.isActive && (
        <MintConfirmationModal
          isOpen={handleMintConfirmationModal.isActive}
          onClose={handleMintConfirmationModal.close}
          onMint={onMint}
        />
      )}
      {handleSuccessMintModal.isActive && (
        <SuccessMintModal
          isOpen={handleSuccessMintModal.isActive}
          onClose={() => {
            handleSuccessMintModal.close();
            //if (enoughFlag) navigate("/game");
          }}
          AVAXPrice={AVAXPrice}
          enoughFlag={true}
          tokenIdList={mintedTokensList}
          sumCommission={zoneSumCommission}
          curAVAX={(balances.balanceAvax).toFixed(2)}
        />
      )}
      {handleAcceptTermsModal.isActive && (
        <AcceptTermsModal
          onClose={handleAcceptTermsModal.close}
          isOpen={handleAcceptTermsModal.isActive}
          onAccept={onAcceptTerms}
        />
      )}
    </div>
  );
};

export default HomeZoneListNew;
