import React, {createContext, useState, useContext, useCallback, useEffect} from 'react';
import {hexToNumber} from "../utils/blockchain";
import useHandleContracts from "../hooks/blockchain/useHandleContracts";
import {useAppContext} from "./appContext";
import useHandleToastAlert from "../hooks/alert/useHandleToastAlert";
import {Context} from "../store";
/* global BigInt */
import {
  ALERT_STATUS_FAILURE,
  ALERT_STATUS_SUCCESS, STAKE_SMRTR_LP_ALERT,
  STAKE_TRESR,
  STAKE_TRESR_LP_ALERT, UNSTAKE_SMRTR_LP_ALERT,
  UNSTAKE_TRESR, UNSTAKE_TRESR_LP_ALERT
} from "../constant/alert";
import {useTokenContext} from "./tokenContext";
import PubSub from "pubsub-js";
import {PubSubEvents} from "../constant/events";
import { roundStaking } from './utils';
import {useWeb3ModalAccount} from "@web3modal/ethers/react";

const StakingContext = createContext({
  refreshStakingBalances: () => {},
  approveTRESR: (value) => {},
  stakeTresr: (value) => {},
  unstakeTresr: (value) => {},
  approveLP: (balance, isTRESR) => {},
  stakeLP: (amount, isTRESR) => {},
  unstakeLP: (amount, isTRESR) => {},
  restrictedStaking: true,
  stakingBalances: {
    balanceTresrLocked: 0,
    balanceTotalTresrLocked: 0,
    balanceTresrStaked: 0,
    balanceTresrStakedAll: 0,
    balanceUnclaimedVeTresr: 0,
    balanceTresrRewardsReleased: 0,
    balanceTotalTresrRewardsPerSec: 0,
    balanceTresrRewardsPerSec: 0,
    balanceLpStakedTRESRAVAX: 0,
    balanceLpStakedSMRTRAVAX: 0,
    balanceLpTotalStakedTRESRAVAX: 0,
    balanceLpTotalStakedSMRTRAVAX: 0,
    balanceLpCommunityStakedTRESRAVAX: 0,
    balanceLpCommunityStakedSMRTRAVAX: 0,
    maxVeTresr: 0,
    daysToMaxVeTresr: 0,
    poolSizeLpTRESRAVAX: 0,
    poolSizeLpSMRTRAVAX: 0,
    unlockTimestamp: 0
  },

});
export const useStakingContext = () => useContext(StakingContext);

const initialBalances = {
  balanceTresrLocked: 0,
  balanceTotalTresrLocked: 0,
  balanceTresrStaked: 0,
  balanceTresrStakedAll: 0,
  balanceUnclaimedVeTresr: 0,
  balanceTresrRewardsReleased: 0,
  balanceTotalTresrRewardsPerSec: 0,
  balanceTresrRewardsPerSec: 0,
  balanceLpStakedTRESRAVAX: 0,
  balanceLpStakedSMRTRAVAX: 0,
  balanceLpTotalStakedTRESRAVAX: 0,
  balanceLpTotalStakedSMRTRAVAX: 0,
  balanceLpCommunityStakedTRESRAVAX: 0,
  balanceLpCommunityStakedSMRTRAVAX: 0,
  maxVeTresr: 0,
  daysToMaxVeTresr: 0,
  poolSizeLpTRESRAVAX: 0,
  poolSizeLpSMRTRAVAX: 0,
  unlockTimestamp: 0
};

export const StakingContextProvider = ({ children }) => {
  const [_, ACTION] = useContext(Context);
  const [stakingBalances, setStakingBalances] = useState(initialBalances);
  const [restrictedStaking, setRestrictedStaking] = useState(true);
  useEffect(() => {
    const logoutToken = PubSub.subscribe(PubSubEvents.LOGOUT, () => {
      setStakingBalances(initialBalances);
    });

    return () => {
      PubSub.unsubscribe(logoutToken);
    };
  }, []);

  const { balances} = useAppContext();
  const { address, isConnected } = useWeb3ModalAccount()
  const { stakedTokenList } = useTokenContext();
  const {
    contractTresrStakingCoinWithSigner,
    contractTresrCoinWithSigner,
    contractNFKeyStakingWithSigner,
    contractLpStakingTRESRAVAXWithSigner,
    contractLpStakingSMRTRAVAXWithSigner,
    contractLpCoinSMRTRAVAXWithSigner,
    contractLpCoinTRESRAVAXWithSigner,
    contractViews
  } = useHandleContracts();
  const handleToastAlert = useHandleToastAlert();

  const refreshStakingBalances = useCallback(async () => {
    if (!isConnected || !contractViews) {
      return;
    }

    const tokenIdList = stakedTokenList.map((item) => item.tokenId);

    const rewards = await contractViews.getStakingBalances(address, tokenIdList);

    const rewardsPerSecondByTokens = Number(rewards.rewardsPerSecondByTokens) / 10**18;
    const rewardPerSecond = Number(rewards.rewardPerSecond)/10**18;
    const userStakedTresr = Number(rewards.userStakedTresr)/10**18;
    const totalStakedTresr = Number(rewards.totalStakedTresr)/10**18;
    const pendingVeTresr = Number(rewards.pendingVeTresr)/10**18;
    const maxVeTresr = Number(rewards.maxVeTresr)/10**18;
    const firstStakeTime = () => {
      const timestamp = Number(rewards.firstStakeTime) * 1000;
      const diff = (Date.now() - timestamp) / 1000;
      const days = 208 - (diff / (3600 * 24));
      return days > 0 ? days : 0;
    }
    const tresrAvaxPortion = Number(rewards.tresrAvaxPortion) / 10**34;
    const userTresrAvaxStaked = Number(rewards.userTresrAvaxStaked) / 10**18;
    const totalTresrAvaxStaked = Number(rewards.totalTresrAvaxStaked)/10**18;
    const smrtrAvaxPortion = Number(rewards.smrtrAvaxPortion)/10**34;
    const userSmrtrAvaxStaked = Number(rewards.userSmrtrAvaxStaked) / 10**18;
    const totalSmrtrAvaxStaked = Number(rewards.totalSmrtrAvaxStaked)/10**18;
    const totalSmrtrAvax = Number(rewards.totalSmrtrAvax)/10**18;
    const totalTresrAvax = Number(rewards.totalTresrAvax)/10**18;
    const tresrLocked = Number(rewards.tresrLocked)/10**18;
    const totalTresrLocked = Number(rewards.totalTresrLocked)/10**18;

    let lock;
    for (let i = 0; i < 100; i++) {
      try {
        const foundLock = await contractTresrStakingCoinWithSigner.locks(i);
        if (foundLock.name === 'diamond') {
          lock = foundLock;
          break;
        }
      } catch {
        break
      }
    }

    const daysToMaxVeTresr = firstStakeTime();
    setStakingBalances((prior) => ({
      ...prior,
      balanceTotalTresrRewardsPerSec: rewardsPerSecondByTokens,
      balanceTresrRewardsPerSec: rewardPerSecond,
      balanceTresrStaked: userStakedTresr,
      balanceTresrStakedAll: totalStakedTresr,
      balanceUnclaimedVeTresr: pendingVeTresr,
      maxVeTresr: maxVeTresr,
      daysToMaxVeTresr: daysToMaxVeTresr,
      balanceLpTotalStakedTRESRAVAX: tresrAvaxPortion,
      balanceLpTotalStakedSMRTRAVAX: smrtrAvaxPortion,
      balanceLpCommunityStakedTRESRAVAX: totalTresrAvaxStaked,
      balanceLpStakedTRESRAVAX: userTresrAvaxStaked,
      balanceLpCommunityStakedSMRTRAVAX: totalSmrtrAvaxStaked,
      balanceLpStakedSMRTRAVAX: userSmrtrAvaxStaked,
      poolSizeLpTRESRAVAX: totalTresrAvax,
      poolSizeLpSMRTRAVAX: totalSmrtrAvax,
      balanceTotalTresrLocked: totalTresrLocked,
      balanceTresrLocked: tresrLocked,
      unlockTimestamp: lock?.release,
    }));

  }, [
    address,
    isConnected,
    stakedTokenList,
    contractNFKeyStakingWithSigner,
    contractLpStakingTRESRAVAXWithSigner,
    contractLpStakingSMRTRAVAXWithSigner,
    contractTresrStakingCoinWithSigner,
    contractLpCoinSMRTRAVAXWithSigner,
    contractLpCoinTRESRAVAXWithSigner,
  ]);

  useEffect(() => {
    if (!isConnected) {
      return;
    }
    refreshStakingBalances()
  }, [isConnected, refreshStakingBalances]);

  useEffect(() => {
    const claimToken = PubSub.subscribe(PubSubEvents.BALANCE_CHANGED, () => {
      refreshStakingBalances();
    });
    return () => {
      PubSub.unsubscribe(claimToken);
    }
  }, [refreshStakingBalances]);

  const approveTRESR = useCallback(async (value, purpose) => {
    if (!isConnected) {
      return;
    }

    if (value > balances.balanceTresr)
      return handleToastAlert.error("Not enough TRESR");

    const purposeAddress = purpose === 'stake' ? process.env.REACT_APP_TRESR_STAKING_ADDRESS :
      purpose === 'chest' ? process.env.REACT_APP_NFKEY_STAKING_ADDRESS : undefined;
    if (!purposeAddress) {
      throw new Error(`Don't have proper address for purpose ${purpose}`)
    }

    const allowance = await contractTresrCoinWithSigner
      .allowance(address, purposeAddress);
    const convertedAllowance = BigInt(allowance);

    const toApprove = roundStaking(value);
    if (convertedAllowance >= toApprove) {
      return true;
    }

    return contractTresrCoinWithSigner
      .approve(purposeAddress, toApprove)
      .then(async (tx) => {
        await tx.wait();
        ACTION.SET_TRANSANCTION_HASH(tx?.hash);
        return true;
      })
      .catch((err) => {
        throw err;
      });
  }, [address, isConnected, balances, contractTresrCoinWithSigner]);

  const stakeTresr = useCallback(async (value) => {
    if (value > balances.balanceTresr)
      return handleToastAlert.error("Not enough TRESR");

    const balanceWei = roundStaking(value);


    return contractTresrStakingCoinWithSigner
      .stake(balanceWei)
      .then(async (tx) => {
        await tx.wait();

        ACTION.SET_TRANSANCTION_HASH(tx?.hash);

        ACTION.SET_ALERT(true, ALERT_STATUS_SUCCESS, STAKE_TRESR(true, value));
      })
      .catch((err) => {
        console.error(balances.balanceTresr, balanceWei);
        console.error(err);
        ACTION.SET_ALERT(true, ALERT_STATUS_FAILURE, STAKE_TRESR(false, value));
        return null;
      });
  }, [balances, contractTresrCoinWithSigner, address, contractTresrStakingCoinWithSigner]);

  const unstakeTresr = useCallback(async (value) => {
    if (value > stakingBalances.balanceTresrStaked)
      return handleToastAlert.error("Trying to unstake more than is staked");

    const amountWei = roundStaking(value);
    return contractTresrStakingCoinWithSigner
      .unstake(amountWei)
      .then(async (tx) => {
        await tx.wait();
        ACTION.SET_TRANSANCTION_HASH(tx?.hash);
        ACTION.SET_ALERT(
          true,
          ALERT_STATUS_SUCCESS,
          UNSTAKE_TRESR(true, value)
        );
      })
      .catch((err) => {
        ACTION.SET_ALERT(
          true,
          ALERT_STATUS_FAILURE,
          UNSTAKE_TRESR(false, value)
        );

        return null;
      });
  }, [stakingBalances, contractTresrStakingCoinWithSigner]);

  const approveLP = useCallback(async (balance, isTRESR = true) => {
    const balanceWei = roundStaking(balance);
    if (isTRESR) {
      const allowance = await contractLpCoinTRESRAVAXWithSigner
        .allowance(address, process.env.REACT_APP_LP_TRESRAVAX_STAKING_ADDRESS);

      if (allowance >= balanceWei) {
        return true;
      }

      return (
        contractLpCoinTRESRAVAXWithSigner
          .approve(
            process.env.REACT_APP_LP_TRESRAVAX_STAKING_ADDRESS,
            balanceWei
          )
          .then(async (tx) => {
            await tx.wait();
            ACTION.SET_TRANSANCTION_HASH(tx?.hash);
            return true;
          })
          .catch((err) => {
            throw err;
          })
      );
    } else {
      const allowance = await contractLpCoinSMRTRAVAXWithSigner
        .allowance(address, process.env.REACT_APP_LP_SMRTRAVAX_STAKING_ADDRESS);

      if (allowance >= balanceWei) {
        return true;
      }

      return (
        contractLpCoinSMRTRAVAXWithSigner
          .approve(
            process.env.REACT_APP_LP_SMRTRAVAX_STAKING_ADDRESS,
            balanceWei
          )
          .then(async (tx) => {
            await tx.wait();
            ACTION.SET_TRANSANCTION_HASH(tx?.hash);
            return true;
          })
          .catch((err) => {
            throw err;
          })
      );
    }
  }, [address, contractLpCoinTRESRAVAXWithSigner, contractLpCoinSMRTRAVAXWithSigner]);

  const stakeLP = useCallback(async (amount, isTRESR = true) => {
    const balanceWei = roundStaking(amount);
    if (isTRESR) {
      await contractLpStakingTRESRAVAXWithSigner
        .deposit(balanceWei)
        .then(async (tx) => {
          await tx.wait();
          ACTION.SET_TRANSANCTION_HASH(tx?.hash);
          ACTION.SET_ALERT(
            true,
            ALERT_STATUS_SUCCESS,
            STAKE_TRESR_LP_ALERT(true, amount)
          );

          return true;
        })
        .catch((err) => {
          console.error(balances.balanceLpTRESRAVAX, balanceWei);
          console.error(err);
          ACTION.SET_ALERT(
            true,
            ALERT_STATUS_FAILURE,
            STAKE_TRESR_LP_ALERT(false, amount)
          );
          return null;
        });
    } else {
      await contractLpStakingSMRTRAVAXWithSigner
        .deposit(balanceWei)
        .then(async (tx) => {
          await tx.wait();
          ACTION.SET_TRANSANCTION_HASH(tx?.hash);
          ACTION.SET_ALERT(
            true,
            ALERT_STATUS_SUCCESS,
            STAKE_SMRTR_LP_ALERT(true, amount)
          );
          return true;
        })
        .catch((err) => {
          console.error(balances.balanceLpSMRTRAVAX, balanceWei);
          console.error(err);
          ACTION.SET_ALERT(
            true,
            ALERT_STATUS_FAILURE,
            STAKE_SMRTR_LP_ALERT(false, amount)
          );
          return null;
        });
    }
  }, [contractLpStakingTRESRAVAXWithSigner, balances, contractLpStakingSMRTRAVAXWithSigner]);

  const unstakeLP = useCallback(async (amount, isTRESR = true) => {
    const balanceWei = roundStaking(amount);
    if (isTRESR) {
      return contractLpStakingTRESRAVAXWithSigner
        .withdraw(balanceWei)
        .then(async (tx) => {
          await tx.wait();
          ACTION.SET_TRANSANCTION_HASH(tx?.hash);
          ACTION.SET_ALERT(
            true,
            ALERT_STATUS_SUCCESS,
            UNSTAKE_TRESR_LP_ALERT(true, amount)
          );
        })
        .catch(err => {
          console.info('error unstaking tresr', err)
          ACTION.SET_ALERT(
            true,
            ALERT_STATUS_FAILURE,
            UNSTAKE_TRESR_LP_ALERT(false, amount)
          );
          return null;
        });
    } else {
      return contractLpStakingSMRTRAVAXWithSigner
        .withdraw(balanceWei)
        .then(async (tx) => {
          await tx.wait();
          ACTION.SET_TRANSANCTION_HASH(tx?.hash);
          ACTION.SET_ALERT(
            true,
            ALERT_STATUS_SUCCESS,
            UNSTAKE_SMRTR_LP_ALERT(true, amount)
          );
        })
        .catch(() => {
          ACTION.SET_ALERT(
            true,
            ALERT_STATUS_FAILURE,
            UNSTAKE_SMRTR_LP_ALERT(false, amount)
          );
          return null;
        });
    }
  }, [contractLpStakingSMRTRAVAXWithSigner, contractLpStakingTRESRAVAXWithSigner]);

  useEffect(() => {
    if (!isConnected || !contractTresrStakingCoinWithSigner) {
      return;
    }
    const checkRestricted = async () => {
      const tresrSSStakingRestricted = await contractTresrStakingCoinWithSigner.restrictStakingToNfkHolders();
      const tresrStakingRestricted = await contractLpStakingTRESRAVAXWithSigner.restrictStakingToNfkHolders();
      const smrtrStakingRestricted = await contractLpStakingSMRTRAVAXWithSigner.restrictStakingToNfkHolders();
      setRestrictedStaking(tresrStakingRestricted || smrtrStakingRestricted || tresrSSStakingRestricted);
    };

    checkRestricted();

    // const opened = PubSub.subscribe(PubSubEvents.CHESTS_OPENED.event, (_, { successes }) => {
    //   if (successes.find(s => !!s)) {
    //     refreshStakingBalances();
    //   }
    // });
    //
    // return () => {
    //   PubSub.unsubscribe(opened);
    // };
  }, [refreshStakingBalances, isConnected, contractLpStakingTRESRAVAXWithSigner, contractLpStakingSMRTRAVAXWithSigner, contractTresrStakingCoinWithSigner]);

  const value = {
    stakingBalances,
    refreshStakingBalances,
    approveTRESR,
    stakeTresr,
    unstakeTresr,
    approveLP,
    stakeLP,
    unstakeLP,
    restrictedStaking,
  };

  return (
    <StakingContext.Provider value={value}>
      {children}
    </StakingContext.Provider>
  );
};
