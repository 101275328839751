import React from 'react';
import MarketplaceButton from "../../common/MarketplaceButton";
import './style.scss'
import ModalLayout from "../../common/Modals";

const ClaimModal = ({
                               isOpen,
                               onClose,
                               marginTop = 0,
                                onClaim,
                                onLock
                           }) => {


    return (
        <ModalLayout
            padding={'40px'}
            onClose={onClose}
            isOpen={isOpen}
            maxWidth={'458px'}
            maxHeight={'392px'}
            withCrossIcon
        >
            <h2 className="modal__title onStakeTRESRModal__title">Configure Reward</h2>
            <p className='modal__text'>A 35% bonus is available for wallets who auto-stake and lock their Diamond
                Allocation for 6 months. Do you want to lock and auto-stake your rewards?</p>
            <p className='modal__text' style={{marginTop: '16px', marginBottom: '16px'}}>WARNING: DECISION IS FINAL AND CANNOT BE CHANGED.</p>

            <div className='modal__actionButtons flex-grow justify-end items-end' style={{marginTop: `${marginTop}px`}}>
                <MarketplaceButton title={'No, Claim and Lose Bonus'} onClick={onClaim}/>
                <MarketplaceButton title={'Yes, Lock and Auto-Stake'} onClick={onLock} isBlue/>
            </div>
        </ModalLayout>
    );
};

export default ClaimModal;
