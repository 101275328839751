import React, {createContext, useState, useContext, useCallback, useEffect} from 'react';
import useHandleContracts from "../hooks/blockchain/useHandleContracts";
import {useTokenContext} from "./tokenContext";
import {hexToNumber} from "../utils/blockchain";
import {useAppContext} from "./appContext";
import PubSub from 'pubsub-js'

import {
  ALERT_STATUS_FAILURE,
  ALERT_STATUS_SUCCESS, CLAIM_ALL_REWARDS_ALERT,
  CLAIM_BONUS_REWARDS_ALERT, CLAIM_FOUNDERS_REWARDS_ALERT,
  CLAIM_VETRESR_ALERT
} from "../constant/alert";
import {Context} from "../store";
import {useStakingContext} from "./stakingContext";
import {PubSubEvents} from "../constant/events";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";

const RewardsContext = createContext({
  rewardsBalances: {
    balanceUnclaimedTotalRewards: 0,
    balanceClaimedBonusTotal: 0,
    balanceUnclaimedBonusRewards: 0,
    balanceBonusVeTresr: 0,
    balanceBonusJlpTresr: 0,
    balanceBonusJlpSmartr: 0,
    balanceBonusKeyLevel: 0,
    balanceBonusTotal: 0,
    veTresrShareP: 0,
    veTresrPerHour: 0,
    KeyLevelP: 0,
    dailyRewards: 0,
    monthlyRewards: 0,
    cumulativeKeyLevel: 0,
    jlpSmrtBonusRewardPerSecond: 0,
    jlpTresrBonusRewardPerSecond: 0,
  },
  refreshRewardsBalances: () => {},
  claimVeTresr: () => {},
  claimBonus: () => {},
  claimAll: () => {},
  claimAllBase: () => {},
  claimBase: () => {},
  startRewardsTimer: () => {},
  stopRewardsTimer: () => {},
});

export const useRewardsContext = () => useContext(RewardsContext);

const initialBalances = {
  balanceUnclaimedTotalRewards: 0,
  balanceUnclaimedBase: 0,
  balanceUnclaimedBonusRewards: 0,
  balanceBonusVeTresr: 0,
  balanceBonusJlpTresr: 0,
  balanceBonusJlpSmartr: 0,
  balanceBonusKeyLevel: 0,
  balanceBonusTotal: 0,
  veTresrShareP: 0,
  veTresrPerHour: 0,
  KeyLevelP: 0,
  dailyRewards: 0,
  monthlyRewards: 0,
  cumulativeKeyLevel: 0,
};
export const RewardsContextProvider = ({ children }) => {
  const [_, ACTION] = useContext(Context);
  const { address, isConnected } = useWeb3ModalAccount()
  const { stakedTokenList, selectedToken } = useTokenContext();
  const [ rewardsTimerRunning, setRewardsTimerRunning ] = useState(false);
  const { refreshStakingBalances } = useStakingContext();

  const startRewardsTimer = useCallback(() => {
    setRewardsTimerRunning(true);
  }, []);

  const stopRewardsTimer = useCallback(() => {
    setRewardsTimerRunning(false);
  }, []);

  const {
    contractNFKeyStakingWithSigner,
    contractMasterRewardsWithSigner,
    contractTresrStakingCoinWithSigner,
    contractViews
  } = useHandleContracts();
  const [rewardsBalances, setRewardsBalances] = useState(initialBalances);

  useEffect(() => {
    const logoutToken = PubSub.subscribe(PubSubEvents.LOGOUT, () => {
      setRewardsBalances(initialBalances);
    });
    return () => {
      PubSub.unsubscribe(logoutToken);
    };
  }, []);


  const refreshRewardsBalances = useCallback(async () => {
    if (!isConnected || !contractViews) {
      return;
    }

    const tokenIdList = stakedTokenList.map((item) => item.tokenId);
    // All these use struct TokenAmount {
    //     address token;
    //     uint amount;
    //     address source;
    // } to accommodate for eventual support of multi-token rewards

    const getTresrAmount = (result) => {
      const token = result.find(r => r.token === process.env.REACT_APP_TRESR_ADDRESS);
      if (!token) {
        return 0;
      }
      return Number(token.amount) / 1e18;
    }

    const balances = await contractViews.getRewardsBalances(address, tokenIdList);

    // {
    //     tresrAvaxEmissionRate,
    //     smrtrAvaxEmissionRate,
    // }

    const pendingBaseReward = Number(balances.pendingBaseReward) / 10**18;
    const tresrStakingUserRewards = getTresrAmount(balances.tresrStakingUserRewards);
    const smrtrAvaxStakingUserRewards = getTresrAmount(balances.smrtrAvaxStakingUserRewards);
    const tresrAvaxStakingUserRewards = getTresrAmount(balances.tresrAvaxStakingUserRewards);
    const nfkeyStakingUserRewards = getTresrAmount(balances.nfkeyStakingUserRewards);
    const allUserRewards = getTresrAmount(balances.allUserRewards);
    const accountEmissionRate = getTresrAmount(balances.accountEmissionRate);
    const totalEmissionRate = getTresrAmount(balances.totalEmissionRate);
    const tresrStakingPortion = Number(balances.tresrStakingPortion) / 1e34;
    const veTresrPerHour = Number(balances.veTresrPerHour)/1e18;
    const nfkeyStakingPortion = Number(balances.nfkeyStakingPortion)/1e34;
    const nfkeyStakedBalance = Number(balances.nfkeyStakedBalance);
    const tresrAvaxEmissionRate = getTresrAmount(balances.tresrAvaxEmissionRate);
    const smrtrAvaxEmissionRate = getTresrAmount(balances.smrtrAvaxEmissionRate);

    // const bonusRewards = await Promise.all([
      // contractMasterRewardsWithSigner
      //   .getAllUserRewardsForLP(address, process.env.REACT_APP_TRESR_STAKING_ADDRESS)
      //   .then((res) => getTresrAmount(res)),
      // contractMasterRewardsWithSigner
      //   .getAllUserRewardsForLP(address, process.env.REACT_APP_LP_SMRTRAVAX_STAKING_ADDRESS)
      //   .then((res) => getTresrAmount(res)),
      // contractMasterRewardsWithSigner
      //   .getAllUserRewardsForLP(address, process.env.REACT_APP_LP_TRESRAVAX_STAKING_ADDRESS)
      //   .then((res) => getTresrAmount(res)),
      // contractMasterRewardsWithSigner
      //   .getAllUserRewardsForLP(address, process.env.REACT_APP_NFKEY_STAKING_ADDRESS)
      //   .then((res) => getTresrAmount(res)),
      // contractMasterRewardsWithSigner
      //   .getAllUserRewards(address)
      //   .then((res) => getTresrAmount(res)),
      // contractMasterRewardsWithSigner
      //   .emissionRateForAccount(address)
      //   .then(async (res) => getTresrAmount(res)),
      // contractMasterRewardsWithSigner
      //   .totalEmissionRate()
      //   .then(async (res) => getTresrAmount(res)),
      // contractTresrStakingCoinWithSigner
      //   .portion(address)
      //   .then((tx) => Number(tx) / 1e34),
      // contractTresrStakingCoinWithSigner
      //   .veTresrPerHour(address)
      //   .then((res) => {
      //     return Number(res) / 1e18;
      //   }),
    //   contractNFKeyStakingWithSigner
    //     .portion(address)
    //     .then((tx) => Number(tx) / 1e34),
    //   contractNFKeyStakingWithSigner
    //     .stakedBalanceOf(address)
    //     .then((res) => Number(res))
    // ]);

    // const emissions = await Promise.all([
    //   contractMasterRewardsWithSigner
    //     .emissionRateForLP(process.env.REACT_APP_LP_TRESRAVAX_TOKEN_ADDRESS)
    //     .then((tx) =>
    //       getTresrAmount(tx)
    //     ),
    //   contractMasterRewardsWithSigner
    //     .emissionRateForLP(process.env.REACT_APP_LP_SMRTRAVAX_STAKING_ADDRESS)
    //     .then((tx) =>
    //       getTresrAmount(tx)
    //     )
    // ]);

    const unclaimedBonus = allUserRewards;
    const dailyRewards = accountEmissionRate * 3600 * 24;
    const monthlyRewards = dailyRewards * 30;


    const unclaimedTotal = unclaimedBonus + pendingBaseReward;

    setRewardsBalances((prior) => ({
      ...prior,
      balanceUnclaimedBase: pendingBaseReward,
      balanceUnclaimedTotalRewards: unclaimedTotal,
      balanceBonusVeTresr: tresrStakingUserRewards,
      balanceBonusJlpSmartr: smrtrAvaxStakingUserRewards,
      balanceBonusJlpTresr: tresrAvaxStakingUserRewards,
      balanceBonusKeyLevel: nfkeyStakingUserRewards,
      balanceUnclaimedBonusRewards: allUserRewards,
      balanceBonusTotal: (accountEmissionRate / totalEmissionRate) * 100,
      veTresrShareP: tresrStakingPortion,
      veTresrPerHour: veTresrPerHour,
      KeyLevelP: nfkeyStakingPortion,
      cumulativeKeyLevel: nfkeyStakedBalance,
      dailyRewards,
      monthlyRewards,
      jlpTresrBonusRewardPerSecond: tresrAvaxEmissionRate,
      jlpSmrtBonusRewardPerSecond: smrtrAvaxEmissionRate,
    }));

  }, [
    stakedTokenList,
    isConnected,
    address,
    contractMasterRewardsWithSigner,
    contractNFKeyStakingWithSigner,
    contractTresrStakingCoinWithSigner,
  ]);



  useEffect(() => {
    if (!stakedTokenList) {
      return;
    }
    refreshRewardsBalances()
  }, [stakedTokenList, refreshRewardsBalances]);

  useEffect(() => {
    const claimToken = PubSub.subscribe(PubSubEvents.BALANCE_CHANGED, () => {
      refreshRewardsBalances();
    });
    return () => {
      PubSub.unsubscribe(claimToken);
    }
  }, [refreshRewardsBalances]);

  useEffect(() => {
    let interval;
    if (rewardsTimerRunning) {
      const fetchData = () => {
        refreshRewardsBalances();
        refreshStakingBalances();
      };

      interval = setInterval(fetchData, 60000);
    } else {
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [refreshRewardsBalances, refreshStakingBalances, rewardsTimerRunning]);

  const claimVeTresr = useCallback(async () => {
    if (!isConnected) return;
    return contractTresrStakingCoinWithSigner
      .claimVeTresr()
      .then(async (tx) => {
        await tx.wait();
        ACTION.SET_TRANSANCTION_HASH(tx?.hash);
        ACTION.SET_ALERT(true, ALERT_STATUS_SUCCESS, CLAIM_VETRESR_ALERT(true));
      });
  }, [address, isConnected, contractTresrStakingCoinWithSigner]);

  const claimBonus = useCallback(async () => {
    if (!isConnected) return;

    return contractMasterRewardsWithSigner
      .claimRewards(address)
      .then(async (tx) => {
        await tx.wait();
        ACTION.SET_TRANSANCTION_HASH(tx?.hash);
        ACTION.SET_ALERT(
          true,
          ALERT_STATUS_SUCCESS,
          CLAIM_BONUS_REWARDS_ALERT(true)
        );
      })
      .catch((err) => {
        ACTION.SET_ALERT(
          true,
          ALERT_STATUS_FAILURE,
          CLAIM_BONUS_REWARDS_ALERT(false)
        );
        return null;
      });
  }, [address, isConnected, contractMasterRewardsWithSigner, contractTresrStakingCoinWithSigner]);

  const claimAll = useCallback(async () => {
    if (!isConnected) return;

    const maxBatchSize = 400;
    const tokenIdList = stakedTokenList.map((item) => +item?.tokenId);

    const numBatches = Math.ceil(tokenIdList.length / maxBatchSize);

    for (let i = 0; i < numBatches; i++) {
      const start = i * maxBatchSize;
      const batchIds = tokenIdList.slice(start, start + maxBatchSize);
      const gas = await contractNFKeyStakingWithSigner.claimTokensWithBonus.estimateGas(batchIds);
      await contractNFKeyStakingWithSigner.claimTokensWithBonus(batchIds, { gasLimit: gas * BigInt(130) / BigInt(100) })
        .then(async (tx) => {
          await tx.wait();

        }).catch((err) => {
          console.log(err)
          ACTION.SET_ALERT(
            true,
            ALERT_STATUS_FAILURE,
            CLAIM_ALL_REWARDS_ALERT(false)
          );
          return null;
        });
    }
    ACTION.SET_ALERT(
      true,
      ALERT_STATUS_SUCCESS,
      CLAIM_ALL_REWARDS_ALERT(true)
    );
  }, [isConnected, stakedTokenList, contractNFKeyStakingWithSigner]);

  const claimAllBase = useCallback(async () => {
    if (!isConnected) return;

    const maxBatchSize = 300;
    const tokenIdList = stakedTokenList.map((item) => item.tokenId);

    const numBatches = Math.ceil(tokenIdList.length / maxBatchSize);

    for (let i = 0; i < numBatches; i++) {
      const start = i * maxBatchSize;
      const batchIds = tokenIdList.slice(start, start + maxBatchSize);
      const gas = await contractNFKeyStakingWithSigner.claimByTokens.estimateGas(batchIds);
      await contractNFKeyStakingWithSigner.claimByTokens(batchIds, {gasLimit: gas * BigInt(120) / BigInt(100)})
          .then(async (tx) => {
            await tx.wait();
            ACTION.SET_TRANSANCTION_HASH(tx?.hash);

            ACTION.SET_ALERT(
                true,
                ALERT_STATUS_SUCCESS,
                CLAIM_FOUNDERS_REWARDS_ALERT(true)
            );
          })
          .catch((err) => {
            ACTION.SET_ALERT(
                true,
                ALERT_STATUS_FAILURE,
                CLAIM_FOUNDERS_REWARDS_ALERT(false)
            );

            return null;
          });
    }
  }, [isConnected, stakedTokenList, contractNFKeyStakingWithSigner]);

  const claimBase = useCallback(async () => {
    if (!isConnected) return;
    if (!selectedToken?.tokenId) return;

    return contractNFKeyStakingWithSigner
      .claim(selectedToken?.tokenId)
      .then(async (tx) => {
        await tx.wait();
        ACTION.SET_TRANSANCTION_HASH(tx?.hash);

        ACTION.SET_ALERT(
          true,
          ALERT_STATUS_SUCCESS,
          CLAIM_FOUNDERS_REWARDS_ALERT(true)
        );
      })
      .catch((err) => {
        ACTION.SET_ALERT(
          true,
          ALERT_STATUS_FAILURE,
          CLAIM_FOUNDERS_REWARDS_ALERT(false)
        );

        return null;
      });
  }, [isConnected, selectedToken, contractNFKeyStakingWithSigner]);

  const value = {
    refreshRewardsBalances,
    rewardsBalances,
    claimVeTresr,
    claimBonus,
    claimAll,
    claimAllBase,
    claimBase,
    startRewardsTimer,
    stopRewardsTimer,
  };

  return (
    <RewardsContext.Provider value={value}>
      {children}
    </RewardsContext.Provider>
  );
};
