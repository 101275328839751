import { Context } from "../../../store";
import React, {useCallback, useContext, useMemo, useState} from "react";
import "./style.scss";
import MarketplaceButton from "../../common/MarketplaceButton";
import { formatterUS } from "../../../utils";
import DiamondRewardsDetails from "./DiamondRewardsDetails";
import {useDiamondContext} from "../../../contexts/diamondContext";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import ClaimModal from "./ClaimModal";
import TransactionLoadingModal from "../../common/Modals/TransactionLoadingModal";

export default function DashboardDiamondPoolReward() {
    const { claimTokens, diamondTokens, endTime, lockTokens, startTime, anyLocked, bonusAllocation, totalAllocation } = useDiamondContext();

    const [isOpen, setIsOpen] = useState(false);

    const onToggleDetails = async () => {
        setIsOpen(!isOpen);
    };

    const allUnclaimed = useMemo(() => {
        return Number(diamondTokens.reduce((toRet, token) => toRet + token.amount, BigInt(0))/(BigInt(1e18)))
    }, [diamondTokens])

    const allClaimed = useMemo(() => {
        return Number(diamondTokens.reduce((toRet, token) => toRet + token.claimed, BigInt(0))/(BigInt(1e18)))
    }, [diamondTokens])

    const stakeModal = useHandleModal();
    const handleTransactionLoadingModal = useHandleModal();

    const canConfigure = diamondTokens.every((token) => token.canConfigure)

    const claim = useCallback(async () => {
        if (canConfigure) {
            stakeModal.open();
        } else {
            handleTransactionLoadingModal.open();
            try {
                await claimTokens()
            } finally {
                handleTransactionLoadingModal.close();
            }
        }

    }, [stakeModal, diamondTokens, handleTransactionLoadingModal, canConfigure])

    const onModalClaim = useCallback(async () => {
        stakeModal.close()
        handleTransactionLoadingModal.open();
        try {
            await claimTokens()
        } finally {
            handleTransactionLoadingModal.close();
        }
    }, [handleTransactionLoadingModal, stakeModal, claimTokens])

    const onModalLock = useCallback(async () => {
        stakeModal.close()
        handleTransactionLoadingModal.open();
        try {
            await lockTokens()
            await claimTokens()
        } finally {
            handleTransactionLoadingModal.close();
        }

    }, [lockTokens, claimTokens, stakeModal, handleTransactionLoadingModal])

    const totalVestedDays = useMemo(() => {
        return (endTime - startTime)/(3600*24)
    }, [startTime, endTime])

    const vestedDaysRemaining = useMemo(() => {
        const remaining = (endTime - (Date.now()/1000))/(3600*24);
        if (remaining < 0) {
            return 0
        }

        return remaining
    }, [endTime])

    const percentVested = useMemo(() => {
        return 100 - (vestedDaysRemaining / totalVestedDays) * 100
    }, [vestedDaysRemaining, totalVestedDays])


    if (diamondTokens.length === 0) {
        return <></>
    }

    return (
        <>
            <div className={`diamondReward ${isOpen ? "diamondReward__open" : ""}`}>
                <div className="diamondReward__info">
                    <div className="diamondReward__info--title">Diamond Rewards</div>
                    <div className="flex pl-7">
                        <div
                            className="diamondReward__info--count"
                            onClick={onToggleDetails}
                        >
                            {formatterUS(allUnclaimed)}
                            <div
                                className={`diamondReward__info--arrow ${
                                    isOpen ? "diamondReward__info--arrow__open" : ""
                                }`}
                            />
                        </div>
                    </div>

                    <div
                        className={`diamondReward__details ${
                            isOpen ? "diamondReward__details--open" : ""
                        }`}
                    >
                        {!!diamondTokens?.length &&
                            diamondTokens?.map((item, key) => (
                                <DiamondRewardsDetails token={item} key={key}/>
                            ))}
                    </div>


                </div>
                <div className="diamondReward__details--block">
                  <span className="diamondReward__details--text">
                    Base Allocation
                  </span>
                    <span className="diamondReward__details--value">
                    {totalAllocation.toFixed(1)}
                  </span>
                </div>
                <div className="diamondReward__details--block">
                  <span className="diamondReward__details--text">
                    Bonus Allocation
                  </span>
                    <span className="diamondReward__details--value">
                    {bonusAllocation.toFixed(1)}
                  </span>
                </div>
                <div className="diamondReward__details--block">
                  <span className="diamondReward__details--text">
                    Total Claimed
                  </span>
                    <span className="diamondReward__details--value">
                    {allClaimed.toFixed(1)}
                  </span>
                </div>
                <div className="diamondReward__details--block">
                  <span className="diamondReward__details--text">
                    Vesting Days Remaining
                  </span>
                    <span className="diamondReward__details--value">
                    {vestedDaysRemaining.toFixed(1)}
                  </span>
                </div>
                <div className="diamondReward__details--block">
                  <span className="diamondReward__details--text">
                    % Vested
                  </span>
                    <span className="diamondReward__details--value">
                    {percentVested.toFixed(1)}
                  </span>
                </div>
                <div className="diamondReward__info--subtitle">
                    Available $TRESR to claim
                </div>
                <div className="diamondReward__actions">
                    <MarketplaceButton
                        onClick={claim}
                        title={canConfigure ? "Configure Diamond Allocation" : anyLocked ? 'Claim & Lock' : 'Claim'}
                        isBlue
                    />
                </div>
            </div>
            {stakeModal.isActive && (
                <ClaimModal
                    isOpen={stakeModal.isActive}
                    onClose={stakeModal.close}
                    onClaim={onModalClaim}
                    onLock={onModalLock}
                />
            )}
            {handleTransactionLoadingModal.isActive && (
                <TransactionLoadingModal
                    isOpen={handleTransactionLoadingModal.isActive}
                    onClose={handleTransactionLoadingModal.close}
                />
            )}

        </>
    );
}
